@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 212 100% 100%;
    --foreground: 212 5% 10%;
    --card: 212 50% 100%;
    --card-foreground: 212 5% 15%;
    --popover: 212 100% 100%;
    --popover-foreground: 212 100% 10%;
    --primary: 212 75.4% 53.7%;
    --primary-foreground: 0 0% 100%;
    --secondary: 212 30% 90%;
    --secondary-foreground: 0 0% 0%;
    --muted: 174 30% 95%;
    --muted-foreground: 212 5% 40%;
    --accent: 174 30% 90%;
    --accent-foreground: 212 5% 15%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 212 5% 100%;
    --success: 160 60% 45%;
    --border: 212 30% 82%;
    --input: 212 30% 50%;
    --ring: 212 75.4% 53.7%;
    --radius: 0.5rem;
    --chart-1: 220 70% 50%;
    --chart-2: 0, 60%, 45%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 212 50% 10%;
    --foreground: 212 5% 100%;
    --card: 212 50% 10%;
    --card-foreground: 212 5% 100%;
    --popover: 212 50% 5%;
    --popover-foreground: 212 5% 100%;
    --primary: 212 75.4% 53.7%;
    --primary-foreground: 0 0% 100%;
    --secondary: 212 30% 20%;
    --secondary-foreground: 0 0% 100%;
    --muted: 174 30% 25%;
    --muted-foreground: 212 5% 65%;
    --accent: 174 30% 25%;
    --accent-foreground: 212 5% 95%;
    --destructive: 0 100% 50%;
    --success: 160 60% 45%;
    --destructive-foreground: 212 5% 100%;
    --border: 212 30% 50%;
    --input: 212 30% 50%;
    --ring: 212 75.4% 53.7%;
    --radius: 0.5rem;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
